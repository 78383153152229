import { Moment } from "moment";

export interface Player {
  id: number;
  name: string;
  position: Position;
  price: number;
  team: string;
  score: number;
  ppm: number;
}

export enum Position {
  Keeper = 1,
  Defender = 2,
  Midfielder = 4,
  Attacker = 8,
}

export interface Spot {
  label: string;
  chosenPlayerId?: number;
  calculatedPlayerId?: number;
  bench: boolean;
  stands: boolean;
  position: Position;
  priceRange?: number[];
}

export interface TeamFilter {
  name: string;
  enabled: boolean;
}

export interface Match {
  id: number;
  date: Moment;
  round: number;
  played: boolean;
  homeTeam: string;
  homeTeamFull: string;
  homeOdds: number;
  homeZero: number;
  homeToScore: number;
  homeGoals?: number;
  awayTeam: string;
  awayTeamFull: string;
  awayOdds: number;
  awayZero: number;
  awayToScore: number;
  awayGoals?: number;
  drawOdds: number;
}

export interface PlayerFormMatch {
  id: number;
  homeTeam: string;
  awayTeam: string;
  homeGoals?: number;
  awayGoals?: number;
  date: Moment;
}

export interface PlayerFormStats {
  id: number;
  rating: number;
  minutesPlayed: number;
  substitute: number;
}

export interface PlayerForm {
  match: PlayerFormMatch;
  stats: PlayerFormStats;
  score: number;
}

export interface Captain {
  id: number;
  name: string;
  round: number;
  team: string;
  predictedScore: number;
  recentForm: PlayerForm[];
}

export interface Odd {
  team: string;
  percentage: number;
}

export interface Standing {
  team: string;
  played: number;
  points: number;
  goalsFor: number;
  goalsAgainst: number;
  wins: number;
  draws: number;
  losses: number;
}
