import React, { FunctionComponent } from "react";
import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import { Odd } from "../types";
import { percentToColor } from "../utils";
import TextOverColor from "./TextOverColor";

interface Props {
  odds: Odd[];
  title: string;
}

const OddsRow: FunctionComponent<Props> = ({ odds, title }) => {
  const oddsSorted = odds.sort((a, b) => b.percentage - a.percentage);

  return (
    <Grid container>
      <Grid item xs={12} sm={2}>
        <Typography variant="body2">{title}</Typography>
      </Grid>
      <Grid item xs={12} sm={10} style={{ cursor: "pointer" }}>
        {oddsSorted.map((odd) => (
          <Tooltip title={`${odd.team} ${odd.percentage}%`} placement="top-end">
            <Box
              display="inline-block"
              bgcolor={percentToColor(odd.percentage)}
              key={`odd-${odd.team}-${odd.percentage}`}
              border="1px solid white"
              width="36px"
            >
              <TextOverColor align="center">{odd.team}</TextOverColor>
            </Box>
          </Tooltip>
        ))}
      </Grid>
    </Grid>
  );
};

export default OddsRow;
