import React, { FunctionComponent, useCallback, useState } from "react";
import { Skeleton } from "@material-ui/lab";
import { Box, Card, CardContent, Grid, Typography } from "@material-ui/core";
import RoundSelector from "./RoundSelector";
import useMatches from "../Hooks/useMatches";
import MatchBlock from "./MatchBlock";
import CaptainList from "./CaptainList";
import useCaptains from "../Hooks/useCaptains";
import OddsBlock from "./OddsBlock";
import useStandings from "../Hooks/useStandings";
import StandingList from "./StandingList";

const MatchOverview: FunctionComponent = () => {
  const [round, setRound] = useState<number>(0);
  const [matches, loadingMatches] = useMatches(round);
  const [captains, loadingCaptains] = useCaptains();
  const [standings, loadingStandings] = useStandings();

  const handleChangeRound = useCallback((newRound: number) => {
    setRound(newRound);
  }, []);

  if (loadingMatches || loadingCaptains || loadingStandings) {
    return (
      <>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Skeleton variant="rect" width="100%" height="60" />
          </Grid>
          <Grid item xs={6}>
            <Skeleton variant="rect" width="100%" height="60" />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4} lg={3} key="skeleton-match-1">
            <Skeleton variant="rect" width="100%" height="250" />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} key="skeleton-match-2">
            <Skeleton variant="rect" width="100%" height="250" />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={4} lg={3} key="skeleton-match-3">
            <Skeleton variant="rect" width="100%" height="250" />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={4} lg={3} key="skeleton-match-4">
            <Skeleton variant="rect" width="100%" height="250" />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={4} lg={3} key="skeleton-match-5">
            <Skeleton variant="rect" width="100%" height="250" />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={4} lg={3} key="skeleton-match-6">
            <Skeleton variant="rect" width="100%" height="250" />
          </Grid>{" "}
          <Grid item xs={12} sm={6} md={4} lg={3} key="skeleton-match-7">
            <Skeleton variant="rect" width="100%" height="250" />
          </Grid>
        </Grid>
      </>
    );
  }

  if (matches.length < 1) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Card>
            <CardContent>
              <Typography>Geen wedstrijden gevonden?</Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    );
  }

  const showCurrentRoundInformation =
    captains.length > 0 &&
    standings.length > 0 &&
    (captains[0].round === round || round === 0);

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} style={{ marginBottom: "8px" }}>
          <Card>
            <CardContent style={{ padding: "16px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} md={6}>
                  <Typography variant="h5">Scorito suggesties</Typography>
                  <Typography variant="body1" color="textSecondary">
                    Speelronde {matches[0].round}{" "}
                  </Typography>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={6}
                  container
                  direction="row"
                  alignItems="center"
                  justify="flex-end"
                >
                  <Box displayPrint="none">
                    <Typography variant="body2" align="right" display="inline">
                      Speelronde:&nbsp;
                    </Typography>
                    <RoundSelector
                      round={matches[0].round}
                      onChangeRound={handleChangeRound}
                    />
                  </Box>
                </Grid>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} md={showCurrentRoundInformation ? 9 : 12}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <OddsBlock matches={matches} />
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={3}>
                {matches.map((match) => (
                  <Grid
                    item
                    xs={12}
                    md={showCurrentRoundInformation ? 6 : 4}
                    lg={showCurrentRoundInformation ? 4 : 3}
                    key={`match-${match.id}`}
                  >
                    <MatchBlock match={match} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
            {showCurrentRoundInformation && (
              <Grid item xs={12} md={12} alignContent="flex-start">
                <StandingList standings={standings} />
              </Grid>
            )}
          </Grid>
        </Grid>
        {showCurrentRoundInformation && (
          <Grid item xs={12} md={3}>
            <CaptainList captains={captains} />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default MatchOverview;
