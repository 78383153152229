import React, { FunctionComponent } from "react";
import { Grid, Typography } from "@material-ui/core";

interface Props {
  name: string;
  left?: boolean;
  right?: boolean;
}

const TeamBar: FunctionComponent<Props> = ({ name, left }) => {
  return (
    <Grid
      item
      xs={5}
      style={{
        background: `url('./clublogos/${name.toLowerCase()}.png')`,
        backgroundPosition: left ? "left center" : "right center",
        backgroundSize: "30px",
        backgroundRepeat: "no-repeat",
        height: "40px",
        padding: "8px",
      }}
    >
      {/* <img */}
      {/*  alt={name} */}
      {/*  src={`./clublogos/${name.toLowerCase()}.png`} */}
      {/*  height="30px" */}
      {/*  width="100%" */}
      {/*  style={{ */}
      {/*    objectFit: "cover", */}
      {/*    float: left ? "left" : "right", */}
      {/*  }} */}
      {/* /> */}
      <Typography align={left ? "right" : "left"}>{name}</Typography>
    </Grid>
  );
};

export default TeamBar;
