import React, { useEffect } from "react";
import { Container } from "@material-ui/core";
import moment from "moment";
import "moment/locale/nl";
import MatchOverview from "./MatchOverview";

function App() {
  useEffect(() => {
    moment.locale("nl");
  }, []);

  return (
    <Container fixed>
      <MatchOverview />
    </Container>
  );
}

export default App;
