import { useEffect, useState } from "react";
import moment from "moment";
import { Match } from "../types";
import { oddsToPercent } from "../utils";
import { API_URI } from "../constants";

const useMatches = (round: number): [Match[], boolean] => {
  const [matches, setMatches] = useState<Match[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${API_URI}matches`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ round }),
      // body data type must match "Content-Type" header
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.matches) {
          const resultMatches: Match[] = data.matches.map((m: any) => {
            return {
              id: m.id,
              date: moment(m.localDate),
              round: m.round,
              played: m.played === 1,
              homeTeam: m.home_team_short,
              homeTeamFull: m.home_team_name,
              homeOdds: oddsToPercent(m.odds_home),
              homeZero: oddsToPercent(m.zero_home),
              homeToScore: oddsToPercent(m.to_score_home),
              homeGoals: m.home_goals,
              awayTeam: m.away_team_short,
              awayTeamFull: m.away_team_name,
              awayOdds: oddsToPercent(m.odds_away),
              awayZero: oddsToPercent(m.zero_away),
              awayToScore: oddsToPercent(m.to_score_away),
              awayGoals: m.away_goals,
              drawOdds: oddsToPercent(m.odds_draw),
            } as Match;
          });
          setMatches(resultMatches);
        }
        setIsLoading(false);
      });
  }, [round]);

  return [matches, isLoading];
};

export default useMatches;
