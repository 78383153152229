import chroma from "chroma-js";
import { Player, Spot, TeamFilter } from "./types";

export function playerOfSpot(
  spot: Spot,
  players: Player[]
): Player | undefined {
  if (!spot.chosenPlayerId || spot.chosenPlayerId === 0) {
    if (!spot.calculatedPlayerId || spot.calculatedPlayerId === 0) {
      return undefined;
    }
    return players.find((p) => p.id === spot.calculatedPlayerId);
  }
  return players.find((p) => p.id === spot.chosenPlayerId);
}

/**
 * Returns all VALID players for a spot, takes into account
 * - Fits withing budget (moneyleft)
 * - 4 players per team
 * - already chosen players
 * - price range for spot
 * - team filters
 * Returns the list ordered on PPM DESC
 */
export function availablePlayersForSpot(
  spots: Spot[],
  spot: Spot,
  players: Player[],
  moneyLeft: number,
  teamFilter: TeamFilter[]
): Player[] {
  const teamCounts: Record<string, number> = {};
  const invalidTeams: string[] = [];

  spots.forEach((s) => {
    const p = playerOfSpot(s, players);
    if (p) {
      if (!teamCounts[p.team]) {
        teamCounts[p.team] = 1;
      } else {
        teamCounts[p.team] += 1;
        if (teamCounts[p.team] === 4) {
          invalidTeams.push(p.team);
        }
      }
    }
  });

  return players
    .filter((p) => {
      // Currently chosen
      if (p.id === spot.chosenPlayerId || p.id === spot.calculatedPlayerId) {
        return true;
      }

      // Already chosen somewhere else
      if (
        spots.find(
          (s) => s.chosenPlayerId === p.id || s.calculatedPlayerId === p.id
        )
      ) {
        return false;
      }

      // Player not on spot position
      if (p.position !== spot.position) {
        return false;
      }

      // Max per team
      if (invalidTeams.indexOf(p.team) > -1) {
        return false;
      }

      // Teamfilter
      const tf = teamFilter.find((f) => f.name === p.team);
      if (tf && !tf.enabled) {
        return false;
      }

      if (spot.priceRange) {
        if (
          p.price < spot.priceRange[0] * 1000 ||
          p.price > spot.priceRange[1] * 1000
        ) {
          return false;
        }
      }

      // Within budget
      return p.price <= moneyLeft;
    })
    .sort((a, b) => b.ppm - a.ppm);
}

export function getSpotsCost(spots: Spot[], players: Player[]): number {
  let res = 0;
  spots.forEach((spot) => {
    res += playerOfSpot(spot, players)?.price ?? 0;
  });
  return res;
}

export function findBestPlayers(
  spots: Spot[],
  players: Player[],
  budget: number,
  teamFilter: TeamFilter[]
): Spot[] {
  const newSpots = spots;

  // Fill spots with highest PPM players (simple and naive)
  spots.forEach((spot, index) => {
    let p: Player | undefined;
    if ((spot.chosenPlayerId ?? 0) > 0) {
      p = playerOfSpot(spot, players);
    } else {
      const options = availablePlayersForSpot(
        newSpots,
        spot,
        players,
        budget,
        teamFilter
      );
      p = options.shift();
    }
    if (!p) {
      console.error("Could not find player for spot", spot);
    } else {
      console.log(`Picking initial player for ${spot.label}: ${p.name}`);
      newSpots[index] = {
        ...spot,
        calculatedPlayerId: p.id,
      };
    }
  });

  let bestUpgradePlayerId = 1;
  let bestUpgradeScore = 0;
  let bestUpgradeIndex = 0;
  // Now for the magic
  while (bestUpgradePlayerId > 0) {
    bestUpgradePlayerId = 0;
    bestUpgradeScore = 0;
    // eslint-disable-next-line no-loop-func
    newSpots.forEach((spot, index) => {
      const moneyLeft = budget - getSpotsCost(newSpots, players);
      // as long as we have money
      if (moneyLeft > 0) {
        const options = availablePlayersForSpot(
          newSpots,
          spot,
          players,
          moneyLeft,
          teamFilter
        );
        const currentPlayer = playerOfSpot(spot, players);
        // Find a new player within the options that fits the description
        const newPlayer = options.find((o) => {
          // Needs to not be the same player
          if (o.id === spot.calculatedPlayerId) {
            return false;
          }
          // Needs to be within budget
          if (o.price > moneyLeft) {
            return false;
          }
          // Score needs to be higher
          if (o.score > (currentPlayer?.score ?? 0)) {
            return true;
          }
          return false;
        });
        // Found one
        if (newPlayer && currentPlayer) {
          // Check if it is the best upgrade we can do this loop
          if (bestUpgradeScore < newPlayer.score - currentPlayer.score) {
            bestUpgradeScore = newPlayer.score - currentPlayer.score;
            bestUpgradePlayerId = newPlayer.id;
            bestUpgradeIndex = index;
          }
        }
      }
    });

    // Update the spots with the found player
    if (bestUpgradePlayerId > 0) {
      const oldPlayer = players.find(
        // eslint-disable-next-line no-loop-func
        (p) => p.id === newSpots[bestUpgradeIndex].calculatedPlayerId
      );
      // eslint-disable-next-line no-loop-func
      const newPlayer = players.find((p) => p.id === bestUpgradePlayerId);
      console.log(
        `Swapping ${newSpots[bestUpgradeIndex].label}: ${oldPlayer?.name} to ${newPlayer?.name}`
      );
      newSpots[bestUpgradeIndex] = {
        ...newSpots[bestUpgradeIndex],
        calculatedPlayerId: bestUpgradePlayerId,
      };
    }
  }
  return newSpots;
}

export function oddsToPercent(odds: number): number {
  return Math.round((1 / odds) * 100);
}

export function percentToColor(percent: number, played?: boolean): string {
  if (played) {
    return "#9f9f9f";
  }
  const scale = chroma.bezier(["red", "orange", "green"]).scale().colors(25);
  if (percent < 1) {
    return scale[0];
  }
  if (percent > 99) {
    return scale[scale.length - 1];
  }
  return scale[Math.floor(percent / 4)];
}

export function percentToGradient(percent: number, left: boolean): string {
  const chromaColor = chroma(percentToColor(percent));
  return `linear-gradient(${
    left ? "90deg" : "270deg"
  }, rgba(${chromaColor.rgba()}) 50%, rgba(${chromaColor
    .alpha(0)
    .rgba()}) 100%)`;
}
