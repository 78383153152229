import React, { FunctionComponent } from "react";
import { Box, Card, CardContent, Divider, Typography } from "@material-ui/core";
import { Captain } from "../types";
import CaptainRow from "./CaptainRow";

interface Props {
  captains: Captain[];
}

const CaptainList: FunctionComponent<Props> = ({ captains }) => {
  return (
    <Card>
      <CardContent style={{ paddingTop: "4px", paddingBottom: "4px" }}>
        <Box pb={1}>
          <Typography variant="h6">Captain suggesties</Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{ fontSize: "0.8rem" }}
          >
            Score berekend adhv laatste 5 wedstrijden en odds komende wedstrijd
          </Typography>
        </Box>
        <Divider />
        <Box pt={2}>
          {captains.slice(0, 41).map((captain) => (
            <CaptainRow key={`captain-${captain.id}`} captain={captain} />
          ))}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CaptainList;
