import { useEffect, useState } from "react";
import { Standing } from "../types";
import { API_URI } from "../constants";

const useStandings = (): [Standing[], boolean] => {
  const [standings, setStandings] = useState<Standing[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${API_URI}standings`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        if (data.standings) {
          const resultStandings: Standing[] = data.standings.map((s: any) => {
            return {
              team: s.team.short,
              points: s.points,
              played: s.played,
              wins: s.wins,
              draws: s.drays,
              losses: s.losses,
              goalsAgainst: s.goalsAgainst,
              goalsFor: s.goalsFor,
            } as Standing;
          });
          setStandings(resultStandings);
        }
        setIsLoading(false);
      });
  }, []);

  return [standings, isLoading];
};

export default useStandings;
