/* eslint-disable prettier/prettier */
import { Position, Spot } from "./types";

export const PLAYER_FILE = "./allplayers_winter_2020-2021.json";
export const API_URI = "https://www.magicsuggest.com/backend/public/api/";

export const INITIAL_SPOTS: Spot[] = [
  {
    label: "Keeper",
    position: Position.Keeper,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Keeper bench",
    position: Position.Keeper,
    bench: true,
    stands: false,
    priceRange: [1000, 2000],
  },

  {
    label: "Defender 1",
    position: Position.Defender,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Defender 2",
    position: Position.Defender,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Defender 3",
    position: Position.Defender,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Defender bench",
    position: Position.Defender,
    bench: true,
    stands: false,
    priceRange: [0, 2000],
  },
  {
    label: "Defender stands",
    position: Position.Defender,
    bench: false,
    stands: true,
    priceRange: [0, 500],
  },

  {
    label: "Midfielder 1",
    position: Position.Midfielder,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Midfielder 2",
    position: Position.Midfielder,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Midfielder 3",
    position: Position.Midfielder,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Midfielder 4",
    position: Position.Midfielder,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Midfielder bench",
    position: Position.Midfielder,
    bench: true,
    stands: false,
    priceRange: [0, 2000],
  },
  {
    label: "Midfielder stands",
    position: Position.Midfielder,
    bench: false,
    stands: true,
    priceRange: [0, 500],
  },

  {
    label: "Attacker 1",
    position: Position.Attacker,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Attacker 2",
    position: Position.Attacker,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Attacker 3",
    position: Position.Attacker,
    bench: false,
    stands: false,
    priceRange: [0, 8000],
  },
  {
    label: "Attacker bench",
    position: Position.Attacker,
    bench: true,
    stands: false,
    priceRange: [0, 2000],
  },
  {
    label: "Attacker stands",
    position: Position.Attacker,
    bench: false,
    stands: true,
    priceRange: [0, 500],
  },
];
