import React, { FunctionComponent } from "react";
import { Box, Card, CardContent, Divider, Typography } from "@material-ui/core";
import { Match, Odd } from "../types";
import OddsRow from "./OddsRow";

interface Props {
  matches: Match[];
}

const OddsBlock: FunctionComponent<Props> = ({ matches }) => {
  const oddsResult: Odd[] = [];
  const oddsZero: Odd[] = [];
  const oddsToScore: Odd[] = [];

  matches.forEach((match) => {
    if (!match.played) {
      if (match.homeOdds > 0 && match.homeOdds < 100) {
        oddsResult.push({
          team: match.homeTeam,
          percentage: match.homeOdds,
        });
      }
      if (match.awayOdds > 0 && match.awayOdds < 100) {
        oddsResult.push({
          team: match.awayTeam,
          percentage: match.awayOdds,
        });
      }
      if (match.homeZero > 0 && match.homeZero < 100) {
        oddsZero.push({
          team: match.homeTeam,
          percentage: match.homeZero,
        });
      }
      if (match.awayZero > 0 && match.awayZero < 100) {
        oddsZero.push({
          team: match.awayTeam,
          percentage: match.awayZero,
        });
      }
      if (match.homeToScore > 0 && match.homeToScore < 100) {
        oddsToScore.push({
          team: match.homeTeam,
          percentage: match.homeToScore,
        });
      }
      if (match.awayToScore > 0 && match.awayToScore < 100) {
        oddsToScore.push({
          team: match.awayTeam,
          percentage: match.awayToScore,
        });
      }
    }
  });

  if (!oddsToScore.length && !oddsZero.length && !oddsResult.length) {
    return null;
  }

  return (
    <Card>
      <CardContent style={{ paddingTop: "4px" }}>
        <Box pb={1}>
          <Typography variant="h6">Odds gesorteerd</Typography>
        </Box>
        <Divider />
        <Box pt={2}>
          {!!oddsResult.length && <OddsRow odds={oddsResult} title="Winnaar" />}
          {!!oddsZero.length && <OddsRow odds={oddsZero} title="Nul houden" />}
          {!!oddsToScore.length && (
            <OddsRow odds={oddsToScore} title="Scoren" />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default OddsBlock;
