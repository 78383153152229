import React, { FunctionComponent } from "react";
import { Box, Grid, Tooltip, Typography } from "@material-ui/core";
import chroma from "chroma-js";
import { percentToColor } from "../utils";
import TextOverColor from "./TextOverColor";

interface Props {
  played?: boolean;
  titleLeft: string;
  percentageLeft: number;
  titleCenter: string;
  titleRight: string;
  percentageRight: number;
}

const PercentageBar: FunctionComponent<Props> = ({
  played,
  titleCenter,
  titleLeft,
  titleRight,
  percentageLeft,
  percentageRight,
}) => {
  if (percentageLeft < 1 || percentageRight > 99) {
    return (
      <Grid item xs={12}>
        <Typography
          align="center"
          style={{
            fontSize: "12px",
            color: "#CCC",
            paddingRight: "4px",
          }}
        >
          {titleCenter} odds nog niet beschikbaar
        </Typography>
      </Grid>
    );
  }

  return (
    <>
      <Grid item xs={5} style={{ background: "#eaeaea", cursor: "pointer" }}>
        <Tooltip title={titleLeft} placement="top-end">
          <Box
            style={{
              background: percentToColor(percentageLeft, played),
              width: `calc(${25 + percentageLeft * 0.75}%)`,
              paddingLeft: "4px",
              float: "right",
            }}
          >
            <TextOverColor align="left">{percentageLeft}%</TextOverColor>
          </Box>
        </Tooltip>
      </Grid>
      <Grid item xs={2}>
        <Box
          style={{
            background: `linear-gradient(${"90deg"}, rgba(${chroma(
              percentToColor(percentageLeft, played)
            ).rgba()}) 0%, rgba(${chroma(
              percentToColor(percentageRight, played)
            ).rgba()}) 100%)`,
            width: `100%`,
          }}
        >
          <TextOverColor align="center">{titleCenter}</TextOverColor>
        </Box>
      </Grid>
      <Grid item xs={5} style={{ background: "#eaeaea" }}>
        <Tooltip title={titleRight} placement="top-end">
          <Box
            style={{
              background: percentToColor(percentageRight, played),
              width: `calc(${25 + percentageRight * 0.75}%)`,
              paddingRight: "4px",
            }}
          >
            <TextOverColor align="right">{percentageRight}%</TextOverColor>
          </Box>
        </Tooltip>
      </Grid>
    </>
  );
};

export default PercentageBar;
