import { useEffect, useState } from "react";
import moment from "moment";
import { Captain, PlayerForm } from "../types";
import { API_URI } from "../constants";

const useMatches = (): [Captain[], boolean] => {
  const [captains, setCaptains] = useState<Captain[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const mapRecentForm = (formString: string): PlayerForm[] => {
    const formAr = JSON.parse(formString);
    if (!formAr) return [];
    return formAr.map((f: any) => {
      return {
        match: {
          awayGoals: f.match.away_goals,
          awayTeam: f.match.away_team_short,
          date: moment.utc(f.match.date),
          homeGoals: f.match.home_goals,
          homeTeam: f.match.home_team_short,
          id: f.match.id,
        },
        stats: {
          id: f.stats.id,
          minutesPlayed: f.stats.minutes_played,
          rating: f.stats.rating,
          substitute: f.stats.substitute,
        },
        score: f.score,
      } as PlayerForm;
    });
  };

  useEffect(() => {
    setIsLoading(true);
    fetch(`${API_URI}captains`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        const resultCaptains: Captain[] = data.map((m: any) => {
          return {
            id: m.id,
            round: m.round,
            name: m.name,
            team: m.team,
            predictedScore: m.predicted_score,
            recentForm: mapRecentForm(m.recent_form),
          } as Captain;
        });
        setCaptains(resultCaptains);
        setIsLoading(false);
      });
  }, []);

  return [captains, isLoading];
};

export default useMatches;
