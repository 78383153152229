import React, { FunctionComponent, useMemo, useState } from "react";
import { Box, ButtonBase, Grid, Typography } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Sparklines, SparklinesLine } from "react-sparklines";
import { Captain } from "../types";

interface Props {
  captain: Captain;
}

const CaptainRow: FunctionComponent<Props> = ({ captain }) => {
  const [opened, setOpened] = useState<boolean>(false);

  const handleOpen = () => {
    setOpened(!opened);
  };

  const sparkData = useMemo(() => {
    return captain.recentForm
      .map((f) => {
        return f.score;
      })
      .reverse();
  }, [captain.recentForm]);

  return (
    <Grid container>
      <ButtonBase style={{ width: "100%" }} onClick={handleOpen}>
        <Grid
          item
          xs={7}
          container
          direction="row"
          alignItems="center"
          justify="flex-start"
        >
          <Typography variant="body2" noWrap>
            {captain.name}
          </Typography>
        </Grid>
        <Grid
          item
          xs={2}
          container
          direction="row"
          alignItems="center"
          justify="center"
        >
          <Sparklines data={sparkData}>
            <SparklinesLine style={{ stroke: "#000", fill: "#333" }} />
          </Sparklines>
        </Grid>
        <Grid
          item
          xs={2}
          container
          direction="row"
          alignItems="center"
          justify="flex-end"
        >
          <Typography variant="body2">{captain.predictedScore}</Typography>
        </Grid>
        <Grid item xs={1}>
          <Box displayPrint="none">
            {opened ? (
              <ExpandLess color="disabled" />
            ) : (
              <ExpandMore color="disabled" />
            )}
          </Box>
        </Grid>
      </ButtonBase>
      {opened && (
        <>
          {captain.recentForm.map((form) => (
            <Grid container key={`form-${form.stats.id}-${form.match.id}`}>
              <Grid item xs={3}>
                <Typography variant="body2" color="textSecondary">
                  {form.match.date.format("DD-MM")}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    fontWeight:
                      form.match.homeTeam === captain.team ? "bold" : "inherit",
                  }}
                >
                  {form.match.homeTeam}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2" color="textSecondary">
                  {form.match.homeGoals}
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2" color="textSecondary">
                  -
                </Typography>
              </Grid>
              <Grid item xs={1}>
                <Typography variant="body2" color="textSecondary">
                  {form.match.awayGoals}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  style={{
                    fontWeight:
                      form.match.awayTeam === captain.team ? "bold" : "inherit",
                  }}
                >
                  {form.match.awayTeam}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
              >
                <Typography variant="body2" color="textSecondary">
                  {form.score}
                </Typography>
              </Grid>
              <Grid
                item
                xs={1}
                container
                direction="row"
                alignItems="center"
                justify="flex-end"
              >
                <Typography variant="body2" color="textSecondary" />
              </Grid>
            </Grid>
          ))}
        </>
      )}
    </Grid>
  );
};

export default CaptainRow;
