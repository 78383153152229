import React, { FunctionComponent } from "react";
import { Typography } from "@material-ui/core";

type Props = {
  align?: "left" | "right" | "center";
};

const TextOverColor: FunctionComponent<Props> = ({ align, children }) => {
  return (
    <Typography
      align={align}
      style={{
        fontSize: "12px",
        fontWeight: "bold",
        color: "#FFF",
        textShadow: "1px 1px 2px rgba(0, 0, 0, 0.6)",
      }}
    >
      {children}
    </Typography>
  );
};

export default TextOverColor;
