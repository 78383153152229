import React, { FunctionComponent } from "react";
import { Card, Grid, Typography, CardContent, Box } from "@material-ui/core";
import { Match } from "../types";
import PercentageBar from "./PercentageBar";
import TeamBar from "./TeamBar";

interface Props {
  match: Match;
}

const MatchBlock: FunctionComponent<Props> = ({ match }) => {
  return (
    <Card>
      <CardContent style={{ padding: 8, position: "relative" }}>
        <Box textAlign="center" position="absolute" top="0" width="100%">
          <Typography variant="caption" color="textSecondary">
            {match.date.format("dd L LT")}
          </Typography>
        </Box>
        <Grid container spacing={0}>
          <TeamBar name={match.homeTeam} left />
          <Grid item xs={2} style={{ height: "40px", padding: "8px" }}>
            <Typography align="center">-</Typography>
          </Grid>
          <TeamBar name={match.awayTeam} right />
          {match.played && (
            <Grid item xs={12} style={{ height: "30px" }}>
              <Typography align="center">
                {match.homeGoals} - {match.awayGoals}
              </Typography>
            </Grid>
          )}
          <>
            <PercentageBar
              played={match.played}
              titleLeft={`${match.homeTeamFull} wint: ${match.homeOdds}%`}
              percentageLeft={match.homeOdds}
              titleCenter="WIN"
              titleRight={`${match.awayTeamFull} wint: ${match.awayOdds}%`}
              percentageRight={match.awayOdds}
            />
            <Grid item xs={12}>
              <Box height="2px" />
            </Grid>
            <PercentageBar
              played={match.played}
              titleLeft={`${match.homeTeamFull} houdt de 0: ${match.homeZero}%`}
              percentageLeft={match.homeZero}
              titleCenter="NUL"
              titleRight={`${match.awayTeamFull} houdt de 0: ${match.awayZero}%`}
              percentageRight={match.awayZero}
            />
            <Grid item xs={12}>
              <Box height="2px" />
            </Grid>
            <PercentageBar
              played={match.played}
              titleLeft={`${match.homeTeamFull} scoort: ${match.homeToScore}%`}
              percentageLeft={match.homeToScore}
              titleCenter="SCORE"
              titleRight={`${match.awayTeamFull} scoort: ${match.awayToScore}%`}
              percentageRight={match.awayToScore}
            />{" "}
          </>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default MatchBlock;
