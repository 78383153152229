import React, { FunctionComponent } from "react";
import {
  Box,
  Card,
  CardContent,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Standing } from "../types";

interface Props {
  standings: Standing[];
}

const StandingList: FunctionComponent<Props> = ({ standings }) => {
  return (
    <Card>
      <CardContent style={{ paddingTop: "4px", paddingBottom: "12px" }}>
        <Box pb={1}>
          <Typography variant="h6">Stand</Typography>
        </Box>
        <Divider />
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="right">#</TableCell>
              <TableCell>Team</TableCell>
              <TableCell align="right">Gespeeld</TableCell>
              <TableCell align="right">Punten</TableCell>
              <TableCell align="right">DV</TableCell>
              <TableCell align="right">DT</TableCell>
              <TableCell align="right">Saldo</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {standings.map((row, index) => (
              <TableRow key={row.team}>
                <TableCell align="right">{index + 1}</TableCell>
                <TableCell component="th" scope="row">
                  {row.team}
                </TableCell>
                <TableCell align="right">{row.played}</TableCell>
                <TableCell align="right">{row.points}</TableCell>
                <TableCell align="right">{row.goalsFor}</TableCell>
                <TableCell align="right">{row.goalsAgainst}</TableCell>
                <TableCell align="right">
                  {row.goalsFor - row.goalsAgainst}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

export default StandingList;
