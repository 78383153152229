import React, { FunctionComponent } from "react";
import { Button, ButtonGroup } from "@material-ui/core";

interface Props {
  round: number;
  onChangeRound: (newRound: number) => void;
}

const RoundSelector: FunctionComponent<Props> = ({ round, onChangeRound }) => {
  const from = Math.min(Math.max(round - 2, 1), 34 - 4);
  const rounds = [];
  for (let i = 0; i < 5; i += 1) {
    rounds.push(from + i);
  }

  return (
    <ButtonGroup size="small" aria-label="small outlined button group">
      {rounds.map((r) => (
        <Button
          key={`round_${r}`}
          disabled={r === round}
          onClick={() => onChangeRound(r)}
        >
          {r}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default RoundSelector;
